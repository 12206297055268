<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/account/myrag.png" />
                        My Tag
                    </div>
                    <div class="c_d_buttom flex_box">
                             <!-- <button class="btn_blue flex_center" style="margin-right: 20px" @click="exportResult">
                            <img src="../../assets/img/search/export_ic1.png" />
                            Export
                        </button> -->
                        <button class="btn_blue btn_blu flex_center" @click="ResetHistory">
                            <img src="../../assets/img/account/update.png" />
                            Reset
                        </button>
                        <button class="btn_blue flex_center" @click="searchHistory">
                            <img src="../../assets/img/detail/btn_ic5.png" />
                            Search
                        </button>
                    </div>
                </div>
                <div class="right_cont_box">
                    <div class="his_sel_box flex__box">
                        <div class="sel_box">
                            <el-autocomplete class="inline-input" v-model="CategoryVal" :fetch-suggestions="querySearch" placeholder="Company Name" ></el-autocomplete>
                            <!-- <el-select v-model="CategoryVal" placeholder="Company Name">
                                <el-option v-for="item in IndicatorsOptions" :key="item.id" :label="item.companyName" :value="item.companyName"></el-option>
                            </el-select> -->
                        </div>

                        <div class="sel_box">
                            <el-date-picker v-model="value1" type="daterange" range-separator="" value-format="timestamp" start-placeholder="Start Date" :default-time="['00:00:00', '23:59:59']" end-placeholder="End Date"></el-date-picker>
                        </div>
                    </div>

                    <div class="top_tab">
                        <div class="table_box">
                            <el-table ref="UsageList" :data="UsageList" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>
                                <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Date" prop="dateTime" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.aaaId, companyCountry: scope.row.countryCode, companyNameEn: scope.row.companyName } }">{{ scope.row.companyName }}</router-link> -->
                                        <span @click="setid(scope.row)">{{ scope.row.companyName }}</span>
                                    </template>
                                </el-table-column>
                             
                                <el-table-column label="User name" prop="userName" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Browse duration" prop="duration" align="left" show-overflow-tooltip :resizable="false" />
                            </el-table>
                            <div class="page_i_box">
                                <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- List of login duration and times 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">{{ detailObj.titleDate }}</div>
                <div class="detail_pop_list">
                    <div class="detail_pop_title_box flex_center">
                        <div class="detail_pop_title">Date</div>
                        <div class="detail_pop_title">Login duration</div>
                        <div class="detail_pop_title">Number of logins</div>
                    </div>
                    <div class="detail_pop_item flex_center" v-for="(item, index) in detailObj.results" :key="index">
                        <div class="detail_pop_txt">{{ item.Date }}</div>
                        <div class="detail_pop_txt">{{ item.LoginDuration }}</div>
                        <div class="detail_pop_txt">{{ item.NumberOfLogins }}</div>
                    </div>
                </div>
                <div class="txt_right">
                    <button class="btn_export">Yes</button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
            <div class="large_detail_cont">
                <div class="dialog_detail_title">Please select a company name</div>
                <div class="company_box">
                    <div class="comp_top_check flex_center_between_box">
                        <el-checkbox v-model="checkAll" @change="checkedAllCompany">Select All</el-checkbox>
                        <div class="comp_search">
                            <input class="coll_search_inpt" v-model="filterName" type="text" placeholder="Enter company name" @keyup.enter="hanldFilterName()" />
                            <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="hanldFilterName" />
                        </div>
                    </div>
                    <div class="check_item" v-infinite-scroll="infiniteScroll" :infinite-scroll-disabled="routeLoad || noMore" :infinite-scroll-distance="5">
                        <el-checkbox-group v-model="checkedCompanyList">
                            <el-checkbox v-for="(company, index) in companyList" :label="company" :key="index">{{ company.companyName }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="company_b_txt">{{ checkedCompanyList.length }} enterprises were selected</div>
                <div class="btn_comp_box">
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="Tag.xls">
                        <button class="btn_export" @click="exportCompany">Export</button>
                    </download-excel>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getPortfolio } from '../../api/userApi';
export default {
    components: { Page },
    data() {
        return {
            value1: '',
            CategoryVal: '',
            IndicatorsVal: '',
            CountryVal: '',
            YearVal: '',
            IndicatorsOptions: [],
            timesDetailDialog: false, //List of login duration and times 弹框

            tabActiveName: 'CompanyName',
            obj: {
                LastLoginDuration: '10h',
                TotalLogins: '100h',
                AverageDuration: '30h',
                LastViewedQuantity: '100',
                QuantityOfThisMonth: '10',
                TotalViews: '110',
                LastUpdatedQuantity: '2',
                UpdateQuantityThisMonth: '1',
                TotalUpdates: '10',
                LastRechargeAmount: '1000.00',
                AccumulatedRechargeAmount: '10000.00',
                RemainingQuota: '2000.00',
            },

            UsageList: [],

            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },

            detailObj: {
                titleDate: '2022-11',
                results: [
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '12h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                ],
            },

            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            companyList: [],
            DetailsForm: [],
            companyTblList: [],
            pageNo: 1,
            pageSize: 10,
            // 下载
            title: 'Tag',
            json_fields: {
                'Company Name': 'collectionCompany',

                'Date.': 'dateTime',
                Account: 'userName',
                Browseduration: 'Browseduration',
            },
            filterName: '',
            grouid: '',
          
            IndicatorsOption: [],

        };
    },
    mounted() {
        this.getportfoillist();
    },

    methods: {
        querySearch(queryString, cb) {
            var IndicatorsOptions = this.IndicatorsOptions;

            IndicatorsOptions.map(item => {
                item.value = item.companyName;
            });
            var results = queryString ? IndicatorsOptions.filter(this.createFilter(queryString)) : IndicatorsOptions;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            // console.log(queryString);
            return IndicatorsOptions => {
                // console.log(IndicatorsOptions);
                return IndicatorsOptions.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        async getportfoillist() {
            console.log(11);
            const params = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                companyName: this.CategoryVal,
                startTimed: this.value1[0],
                endTimed: this.value1[1],
            };
            let res = await getPortfolio(params);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                this.IndicatorsOptions = res.data.rows;
                console.log(this.UsageList);
            } else {
                this.UsageList = [];
            }
        },
        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));
           let routeData= this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
            });
            window.open(routeData.href, '_blank');
        },
        // 分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getportfoillist();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getportfoillist();
        },
        //分页

        //tab切换
        handleTabClick() {},
        //Order List
        hanldeDown(row) {},
        //Recharge list
        hanldeApply(row) {},
        //List of login duration and times
        hanldeDetails(row) {
            this.timesDetailDialog = true;
        },
        ResetHistory() {
            this.CategoryVal = '';
            this.value1 = '';
        },
        searchHistory() {
            this.getportfoillist();
        },
              //导出按钮
      exportResult() {
            this.pageNo = 1;
            this.pageSize = 20;
            this.getRouteList();
            this.exportDialog = true;
            // this.powerCalculate('export');
        },
        // 滚动加载方法
        infiniteScroll() {
            this.routeLoad = true;
            this.pageNo += 1; // 页码每次滚动+1
            this.getRouteList();
        },
        async getRouteList() {
            const params = {
                page: this.pageNo,
                limit: this.pageSize,
                companyName: this.CategoryVal,
                startTimed: this.value1[0],
                endTimed: this.value1[1],
            };
            // const list = this.locList;
            // if (list.length == 0) {
            //     this.city = [];
            //     this.province = [];
            // } else {
            //     list.forEach(item => {
            //         if (!this.province.includes(item[1])) {
            //             this.province.push(item[1]);
            //         }
            //         if (!this.city.includes(item[2])) {
            //             this.city.push(item[2]);
            //         }
            //     });
            // }
            await getPortfolio(params)
                .then(res => {
                    let list = res.data.rows;
                    for (let i = 0; i < list.length; i++) {
                        this.companyList.push(list[i]);
                    }
                    this.companyTblList = this.companyList;
                    if (list.length < 10) {
                        this.noMore = true;
                    }
                    if (this.companyList.length === res.data.total) {
                        console.log(111);
                        this.noMore = true;
                    }
                    this.routeLoad = false;
                    console.log(this.companyList);
                })
                .catch(res => {});
        },
        exportCompany() {
            this.DetailsForm = this.checkedCompanyList;
        },
        hanldFilterName() {
            let list = this.companyTblList;
            let keyWord = this.filterName;
            var arr = [];
            if (keyWord == '') {
                return (this.companyList = list);
            } else {
                arr = list.filter(item => {
                    return item.company_name_en.includes(keyWord);
                });
                this.companyList = arr;
            }
        },
        //弹窗全选公司
        checkedAllCompany(val) {
            this.checkedCompanyList = val ? this.companyList : [];
        },
    },
};
</script>
<style scoped>
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}
.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.country_box {
    margin-top: 60px;
}
.country_box .title {
    margin-bottom: 0;
}
.history_box {
    margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}
.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}
.btn_blu {
    margin-right: 20px;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}
.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}
.das_top_cont {
    /* margin-top: 50px; */
}
.das_top_item {
    position: relative;
    width: 230px;
    height: 232px;
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.das_top_item .item_img {
    position: absolute;
    left: 95px;
    top: -20px;
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}
.das_top_item .color1 {
    background: #1290c9;
}
.das_top_item .color2 {
    background: #f56b6b;
}
.das_top_item .color3 {
    background: #55b419;
}
.das_top_item .color4 {
    background: #ff7600;
}
.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}
.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: center;
}
.das_top_item .item_group {
    margin-bottom: 10px;
    font-size: 14px;
}
.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    margin-bottom: 8px;
}
.top_tab {
    margin-top: 40px;
}

.result_tab {
    padding: 20px;
}
.flex__box {
    display: flex;
    align-items: center;
}

.oper_down img {
    width: 18px;
    height: 16px;
}

.detail_pop_list {
    margin: 40px auto 0;
}
.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}
.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}
.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}
.detail_pop_title {
    color: #8497ab;
}
.detail_pop_txt {
    color: #022955;
}
.txt_right {
    text-align: right;
    margin-top: 15px;
}
.sel_box {
   
    margin-right: 15px;
}
create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}

.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}

.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}

.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}
</style>
